<nav class="navbar fixed-top navbar-expand-sm">
    <div class="logo cursor-pointer" (click)="reset()">
        <div>{{'page_header.system_name' | translate}}</div>
        <div>{{'page_header.data_on_the_environment_in_denmark' | translate}}</div>
    </div>

    <div class="collapse navbar-collapse top-menu-container" id="navbarItemsContainer">
        <ul class="navbar-nav">
            <li class="menu-item position-relative">
                <a class="py-3 px-2" id="externalLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{"page_header.go_to" | translate}}
                </a>
                <ul class="dropdown-menu sub-menu-panel sub-menu-externalLinks">
                    <li class="sub-menu-item">
                        <a class="dropdown-item" [href]="vandaUrl.link" target="_blank">
                            <span>{{vandaUrl.display}}</span>
                            <i class="fas fa-external-link ml-2"></i>
                        </a>
                    </li>
                    <li class="sub-menu-item">
                        <a class="dropdown-item" [href]="zendeskLink" target="_blank">
                            <span>{{'page_header.help' | translate}}</span>
                            <i class="fas fa-external-link ml-2"></i>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>

        <div class="dropdown locale-selector">
            <div class="dropdown-toggler" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg class="svg-inline--fa fa-globe icon" aria-hidden="true" focusable="false" data-prefix="far" data-icon="globe" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path class="" fill="currentColor"
                        d="M256 464c7.4 0 27-7.2 47.6-48.4c8.8-17.7 16.4-39.2 22-63.6H186.4c5.6 24.4 13.2 45.9 22 63.6C229 456.8 248.6 464 256 464zM178.5 304h155c1.6-15.3 2.5-31.4 2.5-48s-.9-32.7-2.5-48h-155c-1.6 15.3-2.5 31.4-2.5 48s.9 32.7 2.5 48zm7.9-144H325.6c-5.6-24.4-13.2-45.9-22-63.6C283 55.2 263.4 48 256 48s-27 7.2-47.6 48.4c-8.8 17.7-16.4 39.2-22 63.6zm195.3 48c1.5 15.5 2.2 31.6 2.2 48s-.8 32.5-2.2 48h76.7c3.6-15.4 5.6-31.5 5.6-48s-1.9-32.6-5.6-48H381.8zm58.8-48c-21.4-41.1-56.1-74.1-98.4-93.4c14.1 25.6 25.3 57.5 32.6 93.4h65.9zm-303.3 0c7.3-35.9 18.5-67.7 32.6-93.4c-42.3 19.3-77 52.3-98.4 93.4h65.9zM53.6 208c-3.6 15.4-5.6 31.5-5.6 48s1.9 32.6 5.6 48h76.7c-1.5-15.5-2.2-31.6-2.2-48s.8-32.5 2.2-48H53.6zM342.1 445.4c42.3-19.3 77-52.3 98.4-93.4H374.7c-7.3 35.9-18.5 67.7-32.6 93.4zm-172.2 0c-14.1-25.6-25.3-57.5-32.6-93.4H71.4c21.4 41.1 56.1 74.1 98.4 93.4zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z">
                    </path>
                </svg>
                <div class="locale">{{ currentLocale }}</div>
            </div>

            <ul class="dropdown-menu dropdown-menu-end">
                <li (click)="changeLanguage('da')">
                    <div class="dropdown-item menu-item" [class.text-bold]="currentLocale === 'DA'">Dansk</div>
                </li>
                <li (click)="changeLanguage('en')">
                    <div class="dropdown-item menu-item" [class.text-bold]="currentLocale === 'EN'">English</div>
                </li>
            </ul>
        </div>

        <button *ngIf="dmpNotificationToggleEnable" role="button" class="dmp-notification-btn" (click)="toggleShowingNotifications()">
            <i class="fas fa-bell align-self-center">
                <span *ngIf="dmpNotificationCount" class="dmp-notification-count">
                    <span>{{ dmpNotificationCount }}</span>
                </span>
            </i>
        </button>

        <div class="system-information">
            <div class="information-icon" data-toggle="modal" data-target="#miljoportalModal">
                <i class="far fa-2x fa-info-circle"></i>
            </div>
        </div>
    </div>
</nav>

<!-- Miljoportal Modal -->
<div id="miljoportalModal" class="modal fade environment-portal-modal" tabindex="-1" aria-labelledby="miljoportalModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="title modal-header">
                <div>{{ "page_header.miljoportal_modal.help_text" | translate }}</div>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                    <i class="fal fa-times"></i>
                </button>
            </div>
            <h4 class="mb-2 font-weight-bold">{{ "page_header.miljoportal_modal.title" | translate }}</h4>
            <span>
                <p>
                    {{ "page_header.miljoportal_modal.paragraph1" | translate }}
                </p>
                <p></p>
                <p>{{ "page_header.miljoportal_modal.paragraph2" | translate }}</p>
                <p></p>
                <p>
                    {{ "page_header.miljoportal_modal.paragraph3" | translate }}
                </p>
            </span>
            <div>
                <a href="https://www.miljoeportal.dk/" target="_blank">
                    <button class="btn btn-goto-miljoportal">
                        <span class="pe-2"> {{ "page_header.miljoportal_modal.go_to_miljoportal" | translate }} </span>
                        <i class="fas fa-external-link ml-2"></i>
                    </button>
                </a>
            </div>
        </div>
    </div>
</div>