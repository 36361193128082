import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import * as _ from 'lodash';

import { AppConfig } from 'src/app/configurations/app-config';
import { AppSettingsService } from 'src/app/services/infrastructures/app-settings.service';
import { DMPNotificationService } from 'src/app/services/api/dmp-notification.service';

declare const $: any;

@Component({
    selector: 'dataud-page-header',
    templateUrl: './dataud-page-header.component.html',
    styleUrls: ['./dataud-page-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderComponent {

    constructor(
        appSettingsService: AppSettingsService,
        private _cd: ChangeDetectorRef,
        private _dmpNotificationService: DMPNotificationService) {

        const appSettings = appSettingsService.appSettings;

        if (!_.isEmpty(appSettings.GoToVandaAppLink)) {
            this.vandaUrl = {
                link: appSettings.GoToVandaAppLink,
                display: appSettings.GoToVandaAppText
            };
        }

        if (!_.isEmpty(appSettings.ZendeskLink)) {
            this.zendeskLink = appSettings.ZendeskLink;
        }
        this._dmpNotificationService.notificationCount$.subscribe({
            next: count => {
                this.dmpNotificationCount = this.formatNotificationCount(count);
                this.dmpNotificationToggleEnable = count > 0;
                this._cd.markForCheck();
            }
        });
        this.currentLocale = localStorage.getItem(AppConfig.webStorage.localStorage.currentLanguage).toUpperCase();
    }
    public vandaUrl: { link: string; display: string };
    public zendeskLink: string;
    public currentLocale: string;

    // #region DMP notification
    public dmpNotificationCount: string;
    public dmpNotificationToggleEnable: boolean;

    public changeLanguage(locale: "en" | "da"): void {
        if (this.currentLocale !== locale.toUpperCase()) {
            localStorage.setItem(AppConfig.webStorage.localStorage.currentLanguage, locale);
            window.location.reload();
        }
    }

    public reset(): void {
        window.location.href = '/';
    }

    private formatNotificationCount(count: number): string {
        return '' + (count > 9 ? '+' : count) ?? '';
    }

    public toggleShowingNotifications(): void {
        this._dmpNotificationService.toggleShowingNotifications();
    }

    // #endregion
}
