import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ErrorHandler, OnInit, ViewChild, TemplateRef, HostBinding, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Subscription, of, merge, Subject, combineLatest, Observable } from 'rxjs';
import { tap, switchMap, map, catchError, filter } from 'rxjs/operators';

import * as _ from 'lodash';

import { AppConfig } from 'src/app/configurations/app-config';
import { FilterDispatchService } from 'src/app/services/communication/filter-dispatch.service';
import { SearchService } from 'src/app/services/api/search.service';
import { SearchResponse } from 'src/app/services/api/responses/search.response';
import { LoadingIndicatorService } from 'src/app/services/communication/loading-indicator.service';
import { MapInteractionService } from 'src/app/services/communication/map-interaction.service';
import { StationResult } from 'src/app/models/station-result.model';
import { MapMarker } from '../../layout/map/map.models';
import { Filter } from 'src/app/models/filter.model';
import { DownloadService } from 'src/app/services/api/download.service';
import { DownloadRequest } from 'src/app/services/api/requests/download.request';
import { MetaDataService } from 'src/app/services/api/metadata.service';
import { Guid } from 'src/app/utils/guid';
import { MobileViewInteractionService } from 'src/app/services/communication/mobile-view-interaction.service';
import { TypeResult } from 'src/app/models/type-result.model';
import { SampleLocationService } from 'src/app/services/api/sample-location.service';
import { SampleLocationDownloadRequest, SampleLocationSearchRequest } from 'src/app/services/api/requests/sample-location.requests';
import { SampleLocationResponse } from 'src/app/services/api/responses/sample-location.response';
import { SampleLocation } from 'src/app/models/sample-location.model';
import { SearchErrorResponse } from 'src/app/services/api/responses/search-error.response';
import { ToasterService } from 'src/app/services/communication/toaster.service';
import { ToastType } from 'src/app/models/toast.models';
import { SearchRequest, StancodeRefFilterItem } from 'src/app/services/api/requests/search.request';

import {
    AUTO_STYLE,
    animate,
    state,
    style,
    transition,
    trigger
  } from '@angular/animations';
import { AppSettingsService } from 'src/app/services/infrastructures/app-settings.service';
import { HydroStationService } from 'src/app/services/api/hydro-station.service';
import { StringExtensions } from 'src/app/utils/string-extensions';

interface RequestChainItem {
    valid: boolean;
    request?: SearchRequest;
}

interface ResultRow extends TypeResult {
    selected?: boolean;
    isDownloading$?: Observable<boolean>;
}

interface SampleLocationRow extends SampleLocation {
    selected?: boolean;
}

interface MarkerData {
    stations: StationResult[] | SampleLocation[];
}

const DEFAULT_DURATION = 250;


@Component({
    selector: 'dataud-results',
    templateUrl: './dataud-results.component.html',
    styleUrls: ['./dataud-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('collapse', [
          state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
          state('true', style({ height: '0', visibility: 'hidden' })),
          transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
          transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
        ])
      ]
})
export class ResultsComponent implements OnInit, OnDestroy {
    constructor(
        private _metadataService: MetaDataService,
        private _filterDispatcher: FilterDispatchService,
        private _searchService: SearchService,
        private _sampleLocationService: SampleLocationService,
        private _loadingIndicator: LoadingIndicatorService,
        private _mapInteractionService: MapInteractionService,
        private _downloadService: DownloadService,
        private _mobileViewInteractionService: MobileViewInteractionService,
        private _cd: ChangeDetectorRef,
        private _errorHandler: ErrorHandler,
        private _toasterService: ToasterService,
        private _translateService: TranslateService,
        private _settingsService: AppSettingsService,
        @Inject(HydroStationService) private _hydroStationService: HydroStationService,
    ) {
        this._language = localStorage.getItem(AppConfig.webStorage.localStorage.currentLanguage);
        this.setupResultFlow();
        this.setupLoadingIndicatorHandler();

        this._initSubject = new Subject<boolean>();

        this._innerSubscriptions.push(
            combineLatest([
                this._mapInteractionService.clickMarkerData$,
                this._initSubject
            ]).subscribe({
                next: ([data]: any) => {
                    this.popupData = { ...data };
                    // Set latest result date for station if only one station is selected and result mode is SampleLocation
                    if (this.resultMode === 'SampleLocation' && data?.stations?.length === 1) {
                        this.getHydroStation(data.stations[0]);
                    }

                    // Open optional tab as soon as the marker is clicked
                    if (this.resultMode === 'Result') {
                        this.openOptionalTabByMarkerData(data);
                    }
                    this._cd.detectChanges();
                }
            })
        );
        this._innerSubscriptions.push(
            this._stationSubject.pipe(
                filter(station => (station?.mediaName === 'Hydrometri' || station?.mediaName === 'Grundvand')),
                tap(() => {
                    (this.popupData.stations[0] as SampleLocation).isLoadingHydroApiStation = true;
                    this._cd.detectChanges();
                }),
                switchMap((station) => this._hydroStationService.station(station.number).pipe(
                    map((hydroStation) => hydroStation?.latestResult),
                ))).subscribe({
                    next: (latestResultDate) => {
                        let latestResultMsg = '';
                        if (latestResultDate) {
                            const now = new Date();
                            // Check if latest result date is today -> "Latest data received today"
                            if (latestResultDate.setHours(0, 0, 0, 0) === now.setHours(0, 0, 0, 0)) {
                                latestResultMsg = this._translateService.instant('results_section.map_popup.latest_data_received_today');
                            } else {
                                // Calculate diff in days between now and latest result date
                                const diffInTime = now.getTime() - latestResultDate.getTime();
                                const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
                                
                                // "Latest data received x days ago"
                                latestResultMsg = StringExtensions.format(
                                    this._translateService.instant('results_section.map_popup.latest_data_received_x_days_ago'),
                                    Math.ceil(diffInDays)
                                );
                            }

                        }
                        (this.popupData.stations[0] as SampleLocation).isLoadingHydroApiStation = false;
                        (this.popupData.stations[0] as SampleLocation).latestResult = latestResultMsg;
                        this._cd.detectChanges();
                    },
                    error: () => {
                        (this.popupData.stations[0] as SampleLocation).isLoadingHydroApiStation = false;
                        this._cd.detectChanges();
                    }
                })
            )

    }

    private readonly _searchByConstants = this._metadataService.metadata.constants.searchBy;
    private readonly _areaTypeConstants = this._metadataService.metadata.constants.areaType;
    private readonly _maxDownloadableResultCount = this._metadataService.metadata.constants.maxResultsToDownload;

    private _canDownloadAllResults = false;
    public resultMode: 'Result' | 'SampleLocation' = 'Result';
    public hasResults = false;
    public results: ResultRow[] = [];
    public examinationsCount: number;
    public isLoading: boolean;
    public hydroChartsUrl: string;

    private _language: string;
    private _innerSubscriptions: Subscription[] = [];
    private _initSubject: Subject<boolean>;

    private _currentResquest: SearchRequest;

    // headers
    public resultsHeaderKey: string;
    public resultColumnKey = 'results_section.result';

    // Map
    private _mapMarkers: MapMarker[] = [];

    // Marker popup
    @ViewChild('markerPopup', { static: true }) private _markerPopupTemplateRef: TemplateRef<any>;
    public popupData: MarkerData;

    // Collapses
    @HostBinding('class.collapsed') public resultsCollapsed = true;

    // Download
    public downloadAllShown = false;

    // Optional tab
    public hasOptionalTab: boolean;
    public optionalTabShown: boolean;
    public optionalTabMode: 'Point' | 'Station';
    public optionalTabResults: ResultRow[] = [];
    public optionalTabExaminationsCount: number;
    public optionalTabStationDisplay: string;
    private _optionalTabMarker: MapMarker;
    private _optionTabStationIds: string[];
    private _optionalTabCanDownloadAll = false;

    // Sample locations Table
    public sampleLocations: SampleLocationRow[] = [];

    public location = location;

    private _stationSubject = new Subject<SampleLocation>();
    public sampleLocationLatestResult: string;

    // Loading indicator for download
    public isDownloadingAll$;

    // Table
    public trackByIndex = (index: number): number => {
        return index;
    }

    ngOnInit(): void {
        this._mapInteractionService.setMarkerPopup(this._markerPopupTemplateRef);
        this._initSubject.next(true);
        this.hydroChartsUrl = this._settingsService.appSettings.hydroChartsUrl;
    }

    ngOnDestroy(): void {
        this._innerSubscriptions.forEach(sub => sub.unsubscribe());
    }

    private setupResultFlow(): void {
        this._innerSubscriptions.push(
            merge(
                this._filterDispatcher.filter$.pipe(map(filter => {
                    const request = this.toFilterRequest(filter);
                    this._currentResquest = request;
                    const item: RequestChainItem = { valid: true, request };

                    return item;
                })),
                this._filterDispatcher.filterReset$.pipe(map(() => {
                    const item: RequestChainItem = { valid: false };
                    return item;
                }))
            ).pipe(
                tap(() => {
                    this._loadingIndicator.start();
                    this.resetToEmptyResultState();
                }),
                switchMap(item => {
                    if (item.valid) {
                        this.setTextResourceKeys();

                        const request = item.request;

                        if (request.searchBy === 'SampleLocations') {
                            return this.handleSampleLocationRequest(request);
                        }

                        return this.handleSearchResultRequest(request);
                    }

                    this._loadingIndicator.end();
                    return of(null);
                })
            ).subscribe()
        );
    }

    private handleSearchResultRequest(request: SearchRequest): Observable<any> {
        this.resultMode = 'Result';
        this._cd.markForCheck();

        const defaultResponse: SearchResponse = {
            canDownloadAll: false,
            exceedMaxStation: false,
            stations: [],
            results: [],
            errors: []
        };

        return this._searchService.search(request)
            .pipe(
                catchError((error) => {
                    // manually handler error here to keep result pipeline alive for next results
                    this._errorHandler.handleError(error);
                    return of(defaultResponse);
                }),
                tap((response: SearchResponse) => {
                    this._loadingIndicator.end();
                    this.displayResultRows(response);
                    this.handleSearchErrors(response.errors);

                    // Maps
                    this._mapMarkers = this.convertToMarkers(response.stations);
                    this._mapInteractionService.updateMarkers(this._mapMarkers);

                    // only zoom extend for free text case or "select all" case
                    // because other cases are handled by request flow of area filter
                    if (_.isNil(request.area) || request.area.type === this._areaTypeConstants.place) {
                        this._mapInteractionService.zoomExtend(true);
                    }
                })
            );
    }

    private handleSampleLocationRequest(request: SearchRequest): Observable<any> {
        this.resultMode = 'SampleLocation';
        this._cd.markForCheck();

        const sampleLocationRequest: SampleLocationSearchRequest = {
            area: request.area,
            mediaTypes: request.mediaTypes,
            ownersCvr: request.stationOwners,
            operatorsCvr: request.stationOperators,
        };

        const defaultResponse: SampleLocationResponse = {
            stations: [],
            exceedMaxStation: false,
            errors: []
        };

        return this._sampleLocationService.search(sampleLocationRequest).pipe(
            catchError((error) => {
                // manually handler error here to keep result pipeline alive for next results
                this._errorHandler.handleError(error);
                return of(defaultResponse);
            }),
            tap((response: SampleLocationResponse) => {
                this._loadingIndicator.end();
                this.displaySampleLocationRows(response);
                this.handleSearchErrors(response.errors);

                // Maps
                this._mapMarkers = response.exceedMaxStation ? [] : this.convertToMarkers(response.stations);
                this._mapInteractionService.updateMarkers(this._mapMarkers);

                // only zoom extend for free text case or "select all" case because other cases are handled by request flow of area filter
                if (_.isNil(sampleLocationRequest.area) || sampleLocationRequest.area?.type === this._areaTypeConstants.place) {
                    this._mapInteractionService.zoomExtend(true);
                }
            })
        );
    }

    private handleSearchErrors(errors: SearchErrorResponse[]): void {
        if (_.isEmpty(errors)) { return; }

        this._toasterService.toast({
            type: ToastType.GeneralError,
            data: {
                useTranslation: false,
                title: this._translateService.instant('errors.general.title'),
                message: errors.map(e => `[${e.source}] ${e.type}: ${e.message}`).join(`\n`)
            }
        });
    }

    private setupLoadingIndicatorHandler(): void {
        this._innerSubscriptions.push(this._loadingIndicator.loadingState$.subscribe({
            next: state => {
                this.isLoading = state;
                this._cd.markForCheck();
            }
        }));
    }

    private resetToEmptyResultState(): void {
        // main result
        this.hasResults = false;
        this.results = [];
        this._mapMarkers = [];

        // download
        this.downloadAllShown = false;

        // optional tab
        this.hasOptionalTab = false;
        this.optionalTabShown = false;

        // sample location tabel
        this.sampleLocations = [];

        // map
        this._mapInteractionService.updateMarkers([]);
        this._mapInteractionService.hideExceedMaxStationWarning();

        this._cd.markForCheck();
    }

    private convertToMarkers(results: (StationResult | SampleLocation)[]): MapMarker[] {
        const locations = Array.from(new Set(results.map(x => x.location)));
        const markers: MapMarker[] = locations.map(location => {
            const locationString = location;
            const locationStringArray = locationString.split(',');
            const coordinates = [parseFloat(locationStringArray[0]), parseFloat(locationStringArray[1])];
            const data: MarkerData = {
                stations: results.filter(x => x.location === location) as (StationResult[] | SampleLocation[])
            };

            return {
                id: Guid.newGuid().toString(),
                coordinates,
                data
            };
        });

        return markers;
    }

    private displayResultRows(response: SearchResponse, forResults = true): void {
        this.hasResults = true;
        this.results = response.results;
        this.examinationsCount = response.results.map(x => x.examinationCount).reduce((a, b) => a + b, 0);
        this._canDownloadAllResults = response.canDownloadAll && response.results.length > 1;
        this.downloadAllShown = this._canDownloadAllResults;

        if (response.exceedMaxStation) {
            this._mapInteractionService.showExceedMaxStationWarning();
        }

        this.resultsCollapsed = false;
        this._cd.markForCheck();
    }

    private displaySampleLocationRows(response: SampleLocationResponse): void {
        this.hasResults = true;
        this.sampleLocations = response.stations;
        this.examinationsCount = response.stations.length;
        this.downloadAllShown = response.stations.length > 0;

        if (response.exceedMaxStation) {
            this._mapInteractionService.showExceedMaxStationWarning();
        }

        this.resultsCollapsed = false;
        this._cd.markForCheck();
    }

    private toResultRows(stations: StationResult[]): { rows: ResultRow[]; examinationsCount: number } {
        const rows: ResultRow[] = [];

        // build typeValue for result by scList_scCode
        switch (this._currentResquest.searchBy) {
            case this._searchByConstants.chemistries:
            case this._searchByConstants.species:
                stations.forEach(s => {
                    s.results.forEach(r => r.typeValue = `${r.scListId}_${r.scCode}`);
                });
                break;

            default:
                break;
        }

        const allResults = stations.map(x => x.results).reduce((a, b) => a.concat(b), []);
        const typeValues = Array.from(new Set(allResults.map(x => x.typeValue)));
        let examinationsCount = 0;

        typeValues.forEach(typeValue => {
            const resultsByType = allResults.filter(x => x.typeValue === typeValue);
            const sampleTypeResult = resultsByType[0];
            const typeName = sampleTypeResult.typeName;
            let examinationCount = 0;
            let resultCount = 0;
            let minDate = Infinity;
            let maxDate = -Infinity;

            resultsByType.forEach(result => {
                examinationCount += result.examinationCount;
                resultCount += result.resultCount;
                minDate = Math.min(minDate, result.firstExaminationDate.getTime());
                maxDate = Math.max(maxDate, result.lastExaminationDate.getTime());
            });

            examinationsCount += examinationCount;
            rows.push({
                canDownload: resultCount <= this._maxDownloadableResultCount,
                typeValue,
                typeName,
                examinationCount,
                resultCount,
                scListId: sampleTypeResult.scListId,
                scCode: sampleTypeResult.scCode,
                firstExaminationDate: new Date(minDate),
                lastExaminationDate: new Date(maxDate)
            });
        });

        return { rows, examinationsCount };
    }

    private setTextResourceKeys(): void {
        switch (this._currentResquest.searchBy) {
            case this._searchByConstants.examinationTypes:
                this.resultsHeaderKey = 'results_section.examination.header';
                this.resultColumnKey = 'results_section.examination.column_header';
                break;

            case this._searchByConstants.chemistries:
                this.resultsHeaderKey = 'results_section.chemistry.header';
                this.resultColumnKey = 'results_section.chemistry.column_header';
                break;

            case this._searchByConstants.species:
                this.resultsHeaderKey = 'results_section.species.header';
                this.resultColumnKey = 'results_section.species.column_header';
                break;

            case 'SampleLocations':
                this.resultsHeaderKey = 'results_section.sample_locations.header';
                this.resultColumnKey = 'results_section.sample_locations.column_header';
                break;
        }

        this._cd.markForCheck();
    }

    public downloadByType(row: ResultRow, e: Event): void {
        e.stopPropagation();

        const request: DownloadRequest = {
            ...this._currentResquest,
            ...this.toSearchRequestParams([row])
        };
        row.isDownloading$ = this._downloadService.download(request);
    }

    public downloadAll(): void {
        if (this.resultMode === 'Result') {
            let request: DownloadRequest = {
                language: this._language,
                isDownloadAll: true,
                ...this._currentResquest,
            };

            if (this.optionalTabShown) {
                request = {
                    ...request,
                    ...this.toSearchRequestParams(this.optionalTabResults),
                    area: null,
                    stationIds: this._optionTabStationIds
                };
            } else {
                request = {
                    ...request,
                    ...this.toSearchRequestParams(this.results)
                };
            }

            this.isDownloadingAll$ = this._downloadService.download(request);
            return;
        }

        const sampleLocationRequest: SampleLocationDownloadRequest = {
            language: this._language,
            area: this._currentResquest.area,
            mediaTypes: this._currentResquest.mediaTypes,
            ownersCvr: this._currentResquest.stationOwners,
            operatorsCvr: this._currentResquest.stationOperators,
        };

        this.isDownloadingAll$ = this._sampleLocationService.download(sampleLocationRequest);
    }

    private toSearchRequestParams(rows: ResultRow[]): {
        searchValues?: string[],
        searchParameters?: StancodeRefFilterItem[]
    } {
        switch (this._currentResquest.searchBy) {
            case this._searchByConstants.chemistries:
            case this._searchByConstants.species:
                return {
                    searchParameters: rows.map(({ scListId, scCode }) => ({ scListId, scCode }))
                };

            default:
                return { searchValues: rows.map(x => x.typeValue) };
        }
    }

    public toggleResultsCollapse(): void {
        this.resultsCollapsed = !this.resultsCollapsed;
        this._cd.markForCheck();
    }

    public toggleSelectResultRow(row: ResultRow): void {
        // change seleted state
        this.results.forEach(r => {
            if (r === row) { return; }

            r.selected = false;
        });
        row.selected = !row.selected;

        // select markers
        const selectedMarkerIds: string[] = [];

        if (row.selected) {
            this._mapMarkers.forEach(marker => {
                const data = marker.data as MarkerData;
                const stations = data.stations;

                if (stations.some(s => s.results.some(r => r.typeValue === row.typeValue))) {
                    selectedMarkerIds.push(marker.id);
                }
            });
        }

        this._mapInteractionService.selectMarkers(selectedMarkerIds);
    }

    public toggleSelectSampleLocationRow(row: SampleLocationRow): void {
        // change seleted state
        this.sampleLocations.forEach(r => {
            if (r === row) { return; }

            r.selected = false;
        });
        row.selected = !row.selected;

        // select markers
        let selectedMarkerId: string = null;
        let selectedMarker: any = {};

        if (row.selected) {
            this._mapMarkers.forEach(marker => {
                const data = marker.data as MarkerData;
                const stations = data.stations as SampleLocation[];

                if (stations.some(s => s === row)) {
                    selectedMarkerId = marker.id;
                    selectedMarker = marker;
                }
            });
        }
        const markerData = {
            ...selectedMarker,
            stations: selectedMarker?.data?.stations?.filter(x=> x.selected) ?? []
        }
        this._mapInteractionService.openSelectedMarkerPopup(selectedMarkerId, markerData);
        if(selectedMarkerId) this._mapInteractionService.setCenter(selectedMarker.coordinates);
    }

    public openOptionalTabByStations(stations: StationResult[]): void {
        // highligh marker
        this._optionalTabMarker = this._mapMarkers.find(x => x.data.stations === stations);
        this._mapInteractionService.highlightMarkers([this._optionalTabMarker.id]);
        this.openOptionalTab(stations, 'Point');
    }

    public openOptionalTabByStation(station: StationResult): void {
        // highligh marker
        this._optionalTabMarker = this._mapMarkers.find(x => x.data.stations.includes(station));
        this._mapInteractionService.highlightMarkers([this._optionalTabMarker.id]);

        this.optionalTabStationDisplay = _.isEmpty(station.number) ? `${station.name}` : `${station.number} - ${station.name}`;
        this.openOptionalTab([station], 'Station');
    }

    private openOptionalTabByMarkerData(markerData: MarkerData): void {
        const station = markerData.stations[0] as StationResult;
        this.openOptionalTabByStation(station)
    }

    private openOptionalTab(stations: StationResult[], optionalTabMode: 'Point' | 'Station'): void {
        this.hasOptionalTab = true;
        this.optionalTabShown = true;
        this.resultsCollapsed = false;
        this.optionalTabMode = optionalTabMode;
        const convertResult = this.toResultRows(stations);
        this.optionalTabResults = convertResult.rows;
        this.optionalTabExaminationsCount = convertResult.examinationsCount;
        this._optionalTabCanDownloadAll = !convertResult.rows.some(x => !x.canDownload) && convertResult.rows.length > 1;
        this.downloadAllShown = this._optionalTabCanDownloadAll;
        this._optionTabStationIds = stations.map(x => x.id);
        this._cd.markForCheck();
        this._mobileViewInteractionService.requestOpenTab('Result');
    }

    public toggleOptionalTab(shown: boolean): void {
        if (!this.hasOptionalTab) { return; }

        this.optionalTabShown = shown;

        if (shown) {
            this.downloadAllShown = this._optionalTabCanDownloadAll;
            this._mapInteractionService.setCenter(this._optionalTabMarker.coordinates);
        } else {
            this.downloadAllShown = this._canDownloadAllResults;
        }
    }

    public closeOptionalTab(e: Event): void {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.hasOptionalTab = false;
        this.optionalTabShown = false;
        this.downloadAllShown = this._canDownloadAllResults;
        this._mapInteractionService.highlightMarkers([]);
    }

    public downloadByTypeAndStations(row: ResultRow, e: Event): void {
        e.stopPropagation();

        const request: DownloadRequest = {
            ...this._currentResquest,
            ...this.toSearchRequestParams([row]),
            stationIds: this._optionTabStationIds,
            area: null
        };

        row.isDownloading$ = this._downloadService.download(request);
    }

    private toFilterRequest(source: Filter): SearchRequest {
        switch (source.searchBy) {
            case this._searchByConstants.chemistries:
            case this._searchByConstants.species:
                {
                    const searchParameters: StancodeRefFilterItem[] = source.searchValues.map(x => {
                        const scValues = x.split('_');
                        return {
                            scListId: parseInt(scValues[0], 10),
                            scCode: parseInt(scValues[1], 10)
                        };
                    });

                    return {
                        language: this._language,
                        ...source,
                        searchParameters,
                        searchValues: null
                    };
                }
            case "ChemicalParameterGroup":
                {
                    const parameterGroupId = source.searchValues?.[0]
                    return {
                        language: this._language,
                        ...source,
                        searchBy: this._searchByConstants.chemistries,
                        parameterGroupId,
                        searchValues: null,
                    };
                }
            default: return {
                language: this._language,
                ...source
            };
        }
    }

    public closePopup(){
        this._mapInteractionService.closePopup();
    }

    public viewStation(station) {
        this.popupData = {stations: [station]}
        this.getHydroStation(station);
    }

    private getHydroStation(station: SampleLocation) {
        this._stationSubject.next(station);
    }
}
