/**
 * Configurations which are not loading from server
 */
export class AppConfig {
    // keys for web storage
    public static webStorage = {
        localStorage: {
            currentLanguage: 'currentLanguage',
            mapLayer: 'mapLayer',
            mapOverlayLayers: 'mapOverlayLayers',
            welcomDialog: {
                notShowAgain: 'notShowAgain',
            },
            layerControlCollapsed: 'layerControlCollapsed',
        }
    };

    public static language = {
        supportLanguages: ['da', 'en'],
        defaultLanguage: 'da'
    };

    public static map = {
        wfs: {
            lake: {
                srsName: 'EPSG:25832',
                gmlSrs: 'urn:ogc:def:crs:EPSG::25832',
                featureNamespace: 'http://data.gov.dk/schemas/geodanmark60/2/gml3',
            }
        },
        defaultZoomLevel: 0,
        minZoomLevel: 0,
        maxZoomLevel: 13,
        viewExtent: [120000, 5900000, 1000000, 6500000],
        animationDuration: 250
    };

    public static layout = {
        mobileBreakpoint: 1200
    };

    public static polygon = {
        minimumAcceptableDistance: 0.5 // unit meter
    };
}
